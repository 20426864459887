.nav {
  background: rgba(255, 255, 255, 0.027);
  width: 100%;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  left: 0;
  z-index: 100;
  margin-top: -59px;
  transition: all 0.5s ease;
}

.navTop {
  background: rgba(255, 255, 255, 0.91);
}

.navTop .navItem {
  color: var(--main-color);
}

.navList {
  display: flex;
  list-style: none;
  justify-content: center;
}

.navItem {
  padding: 15px 1rem;
  font-family: var(--nav-font);
  color: #fff;
  font-size: 1.2rem;
  transition: all 0.4s ease;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}

.navItem:hover {
  background-color: var(--secondary-color);
  color: var(--main-color);
  cursor: pointer;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.active {
  color: orange !important;
}

.showActive {
  color: orange !important;
}

.showInactive {
  color: var(--main-color);
}

.active:hover,
.showActive:hover {
  color: rgb(255, 190, 68) !important;
}

/* ------ mobile settings --------- */

.barsContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: none;
  text-align: right;
  padding: 1rem 2rem;
  background-color: rgba(255, 255, 255, 0.904);
}

.mobileBars {
  font-size: 2rem;
  color: var(--main-color);
  cursor: pointer;
}

.mobileNavList {
  position: fixed;
  top: 68px;
  left: 0;
  width: 100%;
  background: #374e5c;
  border-top: 1px solid var(--main-color);
  animation: showNav 0.5s ease-in;
  opacity: 0;
  animation-fill-mode: forwards;
}

.mobileNavItem {
  width: 100%;
  text-align: center;
  text-decoration: none;
  color: rgb(199, 205, 231);
  display: inline-block;
  padding: 0.4rem;
  font-family: var(--nav-font);
  cursor: pointer;
}

@media (max-width: 600px) {
  .nav {
    margin-top: -42px;
  }
  .barsContainer {
    display: block;
  }

  .navList {
    display: none;
  }
}

@keyframes showNav {
  100% {
    opacity: 1;
  }
}
