.myWorkSection {
  background: #1c1e1d;
  display: flex;
  justify-content: center;
  max-width: 1400px;
  margin: 0 auto;
  margin-top: 6rem;
}

.myWorkContainer {
  background: var(--main-color);
  display: flex;
  border-radius: 15px;
  padding: 2rem;
  width: 100%;
}

.wording {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  font-family: var(--nav-font);
  color: #fff;
  padding-right: 1rem;
  white-space: nowrap;
}

.projectsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-grow: 1;
  gap: 2rem;
}

.project {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding: 20px 10px 40px;
  text-decoration: none;
  color: var(--main-color);
  border: 2px solid transparent;
  position: relative;
}

.project:hover {
  transform: scale(1.05);
  border: 2px solid orange;
}

.title {
  font-family: var(--name-font);
  font-size: 1.1rem;
  padding-bottom: 1rem;
  color: #041347;
}

.description {
  border-top: 1px solid var(--main-color);
  max-width: 300px;
  margin-top: 10px;
  font-family: var(--nav-font);
}

.img {
  max-width: 100%;
  object-fit: cover;
}

.icon {
  position: absolute;
  bottom: 0;
  font-size: 1.8rem;
  color: var(--main-color);
}

.icon:hover {
  color: orange;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid var(--secondary-color);
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 900px) {
  .myWorkContainer {
    flex-direction: column;
    padding: 30px 15px;
  }

  .wording {
    padding-bottom: 2rem;
  }
}
