.showCase {
  width: 100%;
  height: 100vh;
  background-image: url("./../../imgs/showCaseImg.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.name {
  color: #fff;
  font-weight: 200;
  font-family: var(--name-font);
  font-size: 48px;
  text-align: center;
  margin-top: -80px;
}

.title {
  color: #fff;
  font-weight: 800;
  font-family: var(--name-font);
  font-size: 34px;
  text-align: center;
  padding: 2rem;
}

.initial {
  position: fixed;
  top: 5px;
  left: 40px;
  opacity: 0;
  color: var(--main-color);
  font-weight: 200;
  font-family: var(--name-font);
  font-size: 48px;
  z-index: 1000;
}

.show {
  animation-name: show;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.hide {
  animation-name: hide;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes hide {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@media (pointer: coarse) {
  .showCase {
    background-position: center;
    background-attachment: initial;
  }
}

@media (max-width: 555px) {
  .showCase {
    background-image: url("./../../imgs/showCaseImgMobile.jpg");
  }
}
