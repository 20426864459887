.section {
  max-width: 1400px;
  background: var(--main-color);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  margin: 6rem auto 0;
  place-items: center center;
  border-radius: 15px;
}

.backgroundImg {
  width: 100%;
  height: 100%;
  background: url("./../../imgs/backgroundSectionImg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  /* background-attachment: fixed; */
  border-radius: 15px 0 0 15px;
}

.contentContainer {
  background: var(--secondary-color);
  margin: 2rem;
  border-radius: 10px;
}

.backgroundTitle {
  color: #041347;
  font-family: var(--nav-font);
  padding: 1rem;
  padding-bottom: 0;
}

.content {
  padding: 1rem;
  color: #041347;
  font-family: var(--third-font);
  line-height: 1.5;
  font-size: 1.1rem;
}

.content a {
  text-decoration: none;
  color: rgb(255, 213, 158);
}

.link {
  cursor: pointer;
}

@media (pointer: coarse) {
  .backgroundImg {
    background-position: center;
    background-attachment: initial;
  }
}

@media (max-width: 800px) {
  .section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    padding: 2rem;
  }

  .contentContainer {
    margin: 0;
  }

  .backgroundImg {
    margin-top: 2rem;
    height: 400px;
    border-radius: 15px;
  }
}

@media (max-width: 400px) {
  .section {
    padding: 1.5rem;
  }
  .backgroundImg {
    display: none;
  }
}
