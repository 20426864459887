.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  background: #26282e;
  flex-direction: column;
  margin-top: 5rem;
  text-align: center;
}

.footer p {
  color: white;
  font-family: var(--name-font);
  font-size: 1.2rem;
  padding: 1.5rem;
}

.iconContainer {
  padding: 5px 1rem;
  background: #1c1e1d;
  border-radius: 10px;
}

.iconContainer a {
  text-decoration: none;
  position: relative;
}

.iconContainer a:hover:after {
  content: attr(data-title);
  position: absolute;
  top: 15px;
  left: 0;
  background: var(--secondary-color);
  color: #041347;
  font-family: var(--nav-font);
  z-index: 1000;
  padding: 3px 5px;
  border-radius: 5px;
}

.icon {
  font-size: 2.5rem;
  padding: 0.5rem !important;
  color: #fff;
}

.icon:hover {
  color: var(--secondary-color);
}
