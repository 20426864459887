@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@200;400&family=Playfair+Display&family=Titillium+Web&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --main-color: #1c2f36;
  --secondary-color: #a5b1bf;
  --third-color: rgba(80, 85, 101, 0.3);
  --nav-font: "Titillium Web", sans-serif;
  --name-font: "Josefin Sans", sans-serif;
  --third-font: "Playfair Display", serif;
}

html {
  /* scroll-behavior: smooth; */
  background: #1c1e1d;
}
